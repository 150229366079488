import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import firebase from "firebase/compat/app";
import { BrowserRouter } from "react-router-dom";

const config = {
  apiKey: "AIzaSyD8c_nJgyBVZeLnctMT6oxhFAM0akaPEG4",
  authDomain: "survey-fb-89a10.firebaseapp.com",
  projectId: "survey-fb-89a10",
  storageBucket: "survey-fb-89a10.appspot.com",
  messagingSenderId: "997276484822",
  appId: "1:997276484822:web:c0db112b5510063c95f577",
  measurementId: "G-0R626YEJ0H",
};

firebase.initializeApp(config);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);