import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import firebase from "firebase/compat/app";
import { onValue, ref, set, remove } from "firebase/database";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback } from "react";
import UwbLocationSaveDialog from "./UwbLocationSaveDialog"
import Alert from "./Alert"
import { ReactComponent as BackBtn } from "./svg/backBtn.svg";
import { ReactComponent as SignOut } from "./svg/signOut.svg";
import GPSCoordComponent from "./GPSCoordComponent";
import MapComponent from "./MapComponent";
import SavedLocComponent from "./SavedLocComponent.js";
import { useWindowDimensions } from "./hooks/useWindowDimensions.js";
import SplineSelectDialog from "./SplineSelectDialog.js";

const DevComponent = ({ db }) => {
  const location = useLocation();
  const devId = location.pathname.split("/")[1];
  const [gps, setGps] = useState(null);
  const [lte, setLte] = useState(null);
  const [uploadingTime, setUploadingTime] = useState({});
  const [center, setCenter] = useState(null);
  const [timeForRtk2, setTimeForRtk2] = useState(0);
  const [alertMsg, setAlertMsg] = useState({
    color: "#f44336",
    text: ""
  })
  const [ savedLocs, setSavedLocs ] = useState([]);
  const [ mousedLoc, setMousedLoc ] = useState(null);

  const [ splines, setSplines ] = useState(null);
  const [ dsplydSplines, setDsplydSplines ] = useState([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  // location in db for uploading
  const fileUploadRef = useMemo(
    () => (devId ? ref(db, `survey/${devId}/upload`) : null),
    [db, devId]
  );

  // set gogole map center based on 
  useEffect(() => {
    if (!center && gps) {
      setCenter({ lat: gps.gcs[0], lng: gps.gcs[1] });
    }
  }, [center, gps]);

  // main updater
  useEffect(() => {
    if (!devId) return;
    let now = Date.now() - 5000;
    const itvl = setInterval(() => {
      now = Date.now() - 5000;
    }, 5000);

    const unsubscribeFileUploadResult = onValue(fileUploadRef, (snapshot) => {
      const t = snapshot.val();
      if (t) {
        setUploadingTime(t);
      }
    });

    // onValue listens for changes to database and sets gps data.
    const gpsRef = ref(db, `survey/${devId}/gps`);
    const unsubscribeGps = onValue(gpsRef, (snapshot) => {
      try {
        const t = snapshot.val();
        const d = JSON.parse(t);
        if (d) {
          // eslint-disable-next-line no-undef
          d.gps_ts = Number(BigInt(d.gps_ts) / BigInt(1e6));
          if (d.gps_ts > now) {
            setGps(d);
          }
          if (!!d.time_took_for_fixed_solution_in_sec) {
            setTimeForRtk2(+d.time_took_for_fixed_solution_in_sec.toFixed(1));
          }
        }
      } catch (e) {
        console.error(e);
      }
    });

    // listens for updates to lte in database
    // lte seems to currently be unused
    const lteRef = ref(db, `survey/${devId}/lte`);
    const unsubscribeLte = onValue(lteRef, (snapshot) => {
      try {
        const t = snapshot.val();
        if (t) {
          if (t.ts > now) {
            setLte(t);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });

    // Load savedLocations from db
    const savedLocRef = ref(db, `savedLocation`);
    const unsubscribeSavedLoc = onValue(savedLocRef, (snapshot) => {
      try {
        let t = snapshot.val();
        if (t) {
          setSavedLocs(t);
        } else{
          setSavedLocs([]);
        }
      } catch (e) {
        console.error(e);
      }
    });

    // Load splines
    const splineRef = ref(db, `splines`);
    const unsubscribeSplines = onValue(splineRef, (snapshot) => {
      try {
        let t = snapshot.val();
        if(t) {
          setSplines(t);
        }
      } catch (e) {
        console.error(e);
      }
    });

    return () => {
      unsubscribeFileUploadResult();
      unsubscribeGps();
      unsubscribeLte();
      unsubscribeSavedLoc();
      unsubscribeSplines();
      clearInterval(itvl);
    };
  }, [fileUploadRef, db, devId]);

  const startUpload = () => {
    set(fileUploadRef, {
      completed: 0,
      requested: Date.now(),
      timeTook: 0,
      accepted: 0,
    }).catch((e) => console.log(e));
  };
  
  const [isOpenSave, setIsOpenSave] = useState(false);
  const handleCloseSave = useCallback(() => setIsOpenSave(false), [setIsOpenSave]);
  const handleOpenSave = useCallback(() => setIsOpenSave(true), [setIsOpenSave]);

  const [ isOpenSpline, setIsOpenSpline ] = useState(false);
  const handleCloseSpline = useCallback(() => setIsOpenSpline(false), [setIsOpenSpline]);
  const handleOpenSpline = useCallback(() => setIsOpenSpline(true), [setIsOpenSpline]);

  // window and component sizing
  const InfoPanelWidth = 300;
  const HeightOffset = 120;
  const { height, width } = useWindowDimensions();
  const [mapWidth, mapHeight] = useMemo(
      () => [width - InfoPanelWidth, height - HeightOffset],
      [width, height]
  );

  const deleteLoc = useCallback((name) => {
    remove(ref(db, 'savedLocation/' + name))
      .then(() => {
        handleCloseSave();
        setAlertMsg({
          color: "#039105",
          text: `${name} - GPS data deleted`
        });
      })
      .catch(err => {
        setAlertMsg({
          color: "#f44336",
          text: err.toString()
        });
      });
  }, [db, setAlertMsg, handleCloseSave]);

  return (
    <>
      <main>
        <div className="clearfix">
          <div className="btn-back">
            <Link to="/">
              <BackBtn
                style={{paddingTop: "10px"}}
              />
            </Link>
          </div>
          <div className="signOut">
            <Link to="" onClick={() => firebase.auth().signOut()}>
              <SignOut
                style={{paddingTop: "10px"}}
              />
            </Link>
          </div>
        </div>
        <h1>Survey Device - {devId}</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div
            style={{
              width: mapWidth,
              height: mapHeight
            }}
            className="mapOuter"
          >
            {!!gps && isLoaded &&
              <MapComponent
                gps={gps}
                center={center}
                splines={splines}
                dsplydSplines={dsplydSplines}
                savedLocs={savedLocs}
                mousedLoc={mousedLoc}
              />
            }
          </div>
          <div
            style={{
              width: InfoPanelWidth,
              height: mapHeight,
              maxHeight: mapHeight,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {gps &&
              <GPSCoordComponent
                gps={gps}
                timeForRtk2={timeForRtk2}
                handleOpenSave={handleOpenSave}
                handleOpenSpline={handleOpenSpline}
              />
            }
            <SavedLocComponent
              savedLocs={savedLocs}
              setMousedLoc={setMousedLoc}
              deleteLoc={deleteLoc}
            />
          </div>
        </div>
        {isOpenSave && 
          <UwbLocationSaveDialog
            isOpenSave={isOpenSave}
            onClose={handleCloseSave}
            gps={gps}
            db={db}
            setAlertMsg={setAlertMsg}
          />
        }
        {isOpenSpline && 
          <SplineSelectDialog
            isOpenSpline={isOpenSpline}
            onClose={handleCloseSpline}
            db={db}
            setAlertMsg={setAlertMsg}
            splines={splines}
            dsplydSplines={dsplydSplines}
            setDsplydSplines={setDsplydSplines}
          />
        }
        <Alert
          alertMsg={alertMsg}
          setAlertMsg={setAlertMsg}
        />
      </main>
    </>
  );
};

export default DevComponent;
