import "./App.css";
import SignIn from "./Signin";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import DevComponent from "./DevComponent";
import { UserContext } from "./context";
import { getDatabase } from "firebase/database";
import { Routes, Route } from "react-router-dom";
import DevList from "./DevList";
import piper from "./assets/piper_white_clear.png";

function App() {
  const [user, setUser] = useState(false); // Local signed-in state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((u) => {
      setUser(u);
    });
    return () => unregisterAuthObserver();
  }, [setUser]);

  return (
    <div className="App">
      <nav><img src={piper} height="40" alt="Piper Networks Logo" /></nav>
      <UserContext.Provider value={{ user, setUser }}>
        {!!user ? (
          <Routes>
            <Route path="/" element={<DevList db={getDatabase()} />} />
            <Route path="/:id" element={<DevComponent db={getDatabase()} />} />
          </Routes>
        ) : (
          <SignIn />
        )}
      </UserContext.Provider>
    </div>
  );
}
export default App;
