import * as React from "react";
import {useEffect} from "react"

const Alert = ({
                 alertMsg,
                 setAlertMsg,
               }) => {
  useEffect(() => {
    let to;
    if (alertMsg.text) {
      to = setTimeout(() => {
        setAlertMsg(prev => ({...prev, text: ""}));
      }, 10000)
    }
    return () => clearTimeout(to);
  }, [alertMsg, setAlertMsg]);
  
  return !!alertMsg?.text ? (
    <div className="alert" style={{backgroundColor: alertMsg.color}}>
      <span className="closebtn" onClick={() => setAlertMsg(prev => ({...prev, text: ""}))}>X</span>
      <strong>{alertMsg.text}</strong>
    </div>
  ) : null;
};

export default Alert;