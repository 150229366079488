import React from "react";
import { Trash } from "react-bootstrap-icons";

const SavedLocComponent = ({
    savedLocs,
    setMousedLoc,
    deleteLoc
}) => {
    return (
        <div
            className="card clearfix"
            style={{
                overflow: "scroll",
                overflowX: "hidden"
            }}
        >
            <h2 className="clearfix">
                Saved Locations
            </h2>
            <div>
                {Object.keys(savedLocs).map((key, idx) => (
                    <div
                        className="card-2 clearfix"
                        key={key}
                        style={{
                            padding: "3px 3px",
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "3px"
                        }}
                        onMouseEnter={() => {
                            if (setMousedLoc) setMousedLoc(key)
                        }}
                        onMouseLeave={() => {
                            if (setMousedLoc) setMousedLoc(null)
                        }}
                    >
                        <div
                            style={{
                                width: "90%",
                                textAlign: "left",
                                paddingLeft: "10px"
                            }}
                        >
                            {key}
                        </div>
                        <Trash
                        onClick={() => deleteLoc(key)}
                            style={{
                                width: "10%"
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SavedLocComponent;