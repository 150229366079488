import React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import Dialog from "./Dialog"
import {getRTKLabel} from "./helper"
import {ref, set} from "firebase/database"

const UwbLocationSaveDialog = ({
  isOpenSave,
  onClose,
  gps,
  db,
  setAlertMsg,
}) => {
  const [label, setLabel] = useState("");
  const handleChange = useCallback((ev) => setLabel(ev.target.value), []);
  
  const handleClose = useCallback(() => {
    setLabel("")
    onClose();
  }, [onClose]);

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [isOpenSave]);
  
  const save = useCallback((e) => {
    e.preventDefault();
    if (label) {
      set(ref(db, 'savedLocation/' + label), gps)
        .then(() => {
          handleClose()
          setAlertMsg({
            color: "#039105",
            text: `${label} - GPS data saved`
          })
        })
        .catch(err => {
          setAlertMsg({
            color: "#f44336",
            text: err.toString()
          })
        });
    }
  }, [db, label, gps, setAlertMsg, handleClose])
  return (
    <Dialog
      isOpen={isOpenSave}
      onClose={handleClose}
    >
      <form onSubmit={save}>
        <input
          value={label}
          onChange={handleChange}
          type="text"
          placeholder="UWB node id/name/anchor number"
          ref={inputRef}
        />
        {!!gps &&
          
            <div>
              <dl className="clearfix">
                <dt>Latitude:</dt>
                <dd>{gps.gcs[0]}</dd>
              </dl>
              <dl className="clearfix">
                <dt>Longitude:</dt>
                <dd>{gps.gcs[1]}</dd>
              </dl>
              <dl className="clearfix">
                <dt>Altitude:</dt>
                <dd>{gps.gcs[2]} m</dd>
              </dl>
              <dl className="clearfix">
                <dt>Accu:</dt>
                <dd>{`${gps.accu} m (RTK: ${getRTKLabel(gps.rtk)})`}</dd>
              </dl>
              <button type={"submit"} style={{marginTop: 10}} className="button" disabled={!label}>
                Submit
              </button>
            </div>
          
        }
      </form>
    </Dialog>
  );
};

export default UwbLocationSaveDialog;