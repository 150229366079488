import React, { useCallback, useState } from "react";
import Dialog from "./Dialog";
import { ref, set, remove } from "firebase/database";
import { Trash } from "react-bootstrap-icons";

const SplineSelectDialog = ({
    isOpenSpline,
    onClose,
    db,
    setAlertMsg,
    splines,
    dsplydSplines,
    setDsplydSplines
}) => {

    const [ enableDelete, setEnableDelete ] = useState(false);

    const [ addSplineData, setAddSplineData ] = useState({});
    const handleChange = (e) => {
        const newData = {
            ...addSplineData,
            [e.target.name]: e.target.value
        }
        setAddSplineData(newData);
    }

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleOK = (e) => {
        e.preventDefault();
        const newSplines = Object.keys(splines).filter((name) => {
            return e.target[name].checked;
        });
        setDsplydSplines(newSplines);
        handleClose();
    }

    const handleAddSpline = useCallback((e) => {
        e.preventDefault();
        const { name, url } = addSplineData;
        if (name && url) {
            set(ref(db, 'splines/' + name), url)
                .then(() => {
                    handleClose()
                    setAlertMsg({
                        color: "#039105",
                        text: `${name} - Spline saved`
                    })
                })
                .catch(err => {
                    setAlertMsg({
                        color: "#f44336",
                        text: err.toString()
                    })
                });
        }
    }, [db, splines, addSplineData]);

    const removeSpline = useCallback((name) => {
        remove(ref(db, 'splines/' + name))
            .then(() => {
                setAlertMsg({
                color: "#039105",
                text: `${name} - Spline deleted`
                });
            })
            .catch(err => {
                setAlertMsg({
                color: "#f44336",
                text: err.toString()
                });
            });
    }, []);

    const toggleDelete = (e) => {
        setEnableDelete(e.target.checked);
    }

    return (
        <Dialog
            isOpen={isOpenSpline}
            onClose={handleClose}
        >
         <div>
            <h3 style={{marginTop: "0px"}}>KML Layers</h3>
            <form
                onSubmit={handleOK}
                style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    border: "1px solid rgb(209, 209, 209)",
                    width: "320px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        maxHeight: "300px",
                        overflowY: "scroll"
                    }}
                >
                    {Object.keys(splines).map((key) => (
                        <div
                            key={key}
                            className="card-2 clearfix"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "8px",
                                alignItems: "center"
                            }}
                        >
                            <input
                                type="checkbox"
                                name={key}
                                defaultChecked={dsplydSplines.includes(key)}
                                style={{
                                    margin: "7px"
                                }}
                            />
                            <label
                                htmlFor={key}
                                style={{margin: "5px"}}
                            >
                                {key}
                            </label>
                            {enableDelete &&
                            <Trash
                                onClick={() => removeSpline(key)}
                                style={{
                                    marginRight: "5px"
                                }}
                            />}
                        </div>
                    ))}
                </div>
                <div
                    style={{display: "inline"}}
                >
                    <button
                        type={"submit"}
                        className="button"
                        style={{
                            marginBottom: "10px",
                            marginRight: "10px"
                        }}
                    >
                        OK
                    </button>
                    <input
                        type="checkbox"
                        name="allowDelete"
                        defaultChecked={false}
                        style={{margin: "7px"}}
                        onChange={toggleDelete}
                    />
                    <label
                        htmlFor="allowDelete"
                    >
                        Enable Deleting
                    </label>
                </div>
            </form>
            <form
                style={{display: "inline"}}
                onSubmit={handleAddSpline}
            >
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={handleChange}
                />
                <input
                    type="text"
                    name="url"
                    placeholder="URL"
                    onChange={handleChange}
                />
                <button
                    type="submit"
                    className="button"
                    disabled={!addSplineData.name || !addSplineData.url}
                >
                    Add KML
                </button>
            </form>
         </div>
        </Dialog>
    );
}

export default SplineSelectDialog;