
export function getRTKLabel(val) {
  switch (val) {
    case 0:
      return "no fix";
    case 1:
      return "floating";
    case 2:
      return "fixed";
  }
}

export const downloadAsFile = (blob, filename) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
