import React from "react";
import { getRTKLabel } from "./helper"

const GPSCoordComponent = ({
  gps,
  timeForRtk2,
  handleOpenSave,
  handleOpenSpline
}) => {
    return (
        <div
          className="card clearfix"
          style={{
            height: "40%",
            maxHeight: "246px"
          }}
        >
            <h2 className="clearfix">
              GPS
              <span className="date">
                {new Date(gps.gps_ts).toLocaleString()}
              </span>
            </h2>
            <dl className="clearfix">
              <dt>Latitude:</dt>
              <dd>{gps.gcs[0]}</dd>
            </dl>
            <dl className="clearfix">
              <dt>Longitude:</dt>
              <dd>{gps.gcs[1]}</dd>
            </dl>
            <dl className="clearfix">
              <dt>Altitude:</dt>
              <dd>{gps.gcs[2]} m</dd>
            </dl>
            <dl className="clearfix">
              <dt>Accu:</dt>
              <dd>{`${gps.accu} m (RTK: ${getRTKLabel(gps.rtk)})`}</dd>
            </dl>
            <dl className="clearfix">
              <dt>Satellites:</dt>
              <dd>{gps.usat}</dd>
            </dl>
            <dl className="clearfix">
              <dt>Time took for RTK 2:</dt>
              <dd>{timeForRtk2 > 0 ? `${timeForRtk2} seconds` : "-"} </dd>
            </dl>
            <div
              style={{display: ""}}
            >
              <button
                className="button"
                disabled={gps?.rtk < 0}
                onClick={handleOpenSave}
                style={{
                  padding: "7px 12px 7px 12px",
                  marginLeft: "5px",
                  marginRight: "5px"
                }}
              >
                Save
              </button>
              <button
                className="button"
                onClick={handleOpenSpline}
                style={{
                  padding: "7px 12px 7px 12px",
                  marginLeft: "5px",
                  marginRight: "5px"
                }}
              >
                KML Layers
              </button>
            </div>
          </div>
    );
}

export default GPSCoordComponent;