import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {status: true, adminEmail: "hyungwu@pipernetworks.com"}
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

function SignIn() {
  return (
    <div className={"center"}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    </div>
  );
}

export default SignIn;
