import React, { useState, useCallback }from "react";
import {
    GoogleMap,
    Marker,
    Circle,
    KmlLayer
  } from "@react-google-maps/api";
import greenPin from "./assets/green-pin-small.png";
import yellowPin from "./assets/yellow-pin-small.png";
import redPin from "./assets/red-pin-small.png";

const MapComponent = ({
    gps,
    center,
    splines,
    dsplydSplines,
    savedLocs,
    mousedLoc
}) => {

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function onLoad(map) {
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <div
            className="mapInner card"
            style={{padding: "0px"}}
        >
            <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%", borderRadius: "4px" }}
                mapTypeId={"roadmap"}
                zoom={12}
                center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    keyboardShortcuts: false,
                    //gestureHandling: "none",
                    streetViewControl: false,
                }}
            >
                <Marker
                    icon={gps.rtk === 2 ? greenPin: (gps.rtk === 1 ? yellowPin : redPin) }
                    position={{ lat: gps.gcs[0], lng: gps.gcs[1] }}
                    zIndex={2}
                ></Marker>
                <Circle
                    center={{ lat: gps.gcs[0], lng: gps.gcs[1] }}
                    options={{
                    radius: gps.accu,
                    fillColor: "#90EE90",
                    strokeColor: "#006400",
                    editable: false,
                    clickable: false,
                    draggable: false,
                    }}
                ></Circle>
                {Object.keys(savedLocs).map((key) => (
                    <Marker
                        key={key}
                        position={{
                            lat: savedLocs[key].gcs[0],
                            lng: savedLocs[key].gcs[1]
                        }}
                        zIndex={1}
                        animation={mousedLoc === key ? 1 : 0}
                    />
                ))}
                {dsplydSplines.map((name) => (
                    <KmlLayer
                        key={name}
                        url={splines[name]}
                        options={{ preserveViewport: center != null }}
                    />
                ))}
            </GoogleMap>
        </div>
    );
}

export default MapComponent;