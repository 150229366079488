import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {get, onValue, ref} from "firebase/database";
import { Link } from "react-router-dom";
import {downloadAsFile} from "./helper"
import {remove} from "@firebase/database"
import SavedLocComponent from "./SavedLocComponent";

const DevList = ({ db }) => {
  const [devices, setDevices] = useState([]);
  const [savedLocs, setSavedLocs] = useState({});

  useEffect(() => {
    const rootRef = ref(db, `survey`);
    get(rootRef).then((snapshot) => {
      try {
        const now = Date.now();
        const t = snapshot.val();
        const actives = Object.keys(t).filter((devId) => {
          if (!!t[devId].gps) {
            const gpsData = JSON.parse(t[devId].gps);
            if (new Date(gpsData.gps_ts / 1e6) > now - 10 * 1000) {
              return true;
            }
          }
          return false;
        });
        setDevices(actives);
      } catch (e) {
        console.error(e);
      }
    });
  }, [db]);

  const download = useCallback(() => {
    const blob = new Blob([JSON.stringify(savedLocs)], { type: "application/json;charset=utf-8;" });
    downloadAsFile(blob, `saved_locations_${Date.now()}.json`);
  }, [savedLocs]);
  
  const deleteLoc = useCallback((name) => {
    remove(ref(db, `savedLocation/${name}`)).then(d => {
      console.log(d)
    });
    
  }, [db]);
  
  useEffect(() => {
    const savedLocs = ref(db, `savedLocation`);
    const unsubscribe = onValue(savedLocs, (snapshot) => {
      const data = snapshot.val();
      setSavedLocs(data || {});
    });
    
    return () => unsubscribe();
  }, [db]);
  
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h3>Active survey device</h3>
      <div>
        {devices.map((id) => (
          <div key={id}>
            <Link key={id} to={`/${id}`}>
              {id}
            </Link>
          </div>
        ))}
        {devices.length === 0 && <p>No active device</p>}
      </div>
      <h3>Saved survey locations</h3>
      <button className="button" style={{padding: 10}} onClick={download}>
        Download
      </button>
      <div
        style={{
          maxWidth: "400px",
          marginTop: "5px"
        }}
      >
        <SavedLocComponent
          savedLocs={savedLocs}
          deleteLoc={deleteLoc}
        />
      </div>
    </div>
  );
};

export default DevList;
